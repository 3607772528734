export const INITIAL_FILTERS = {
  agentId: null,
  balanceLimit: {
    from: null,
    to: null,
  },
  refillPercent: {
    from: null,
    to: null,
  },
  refillFix: {
    from: null,
    to: null,
  },
  payoutPercent: {
    from: null,
    to: null,
  },
  payoutFix: {
    from: null,
    to: null,
  },
  adPercent: {
    from: null,
    to: null,
  },
  prepaymentPercent: {
    from: null,
    to: null,
  },
};
