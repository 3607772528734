export const columns = [
  {
    name: 'agent-name',
    field: (row) => row.user.username,
    label: 'Agent name',
  },
  {
    name: 'currency',
    field: (row) => row.user.currency?.name,
    label: 'Currency',
  },
  {
    name: 'balance-limit',
    field: 'balanceLimit',
    label: 'Balance limit',
  },
  {
    name: 'refill-percent',
    field: 'refillPercent',
    label: 'Fee for deposit (%)',
  },
  {
    name: 'refill-fix',
    field: 'refillFix',
    label: 'Fee for deposit fixed',
  },
  {
    name: 'payout-percent',
    field: 'payoutPercent',
    label: 'Fee for payout (%)',
  },
  {
    name: 'payout-fix',
    field: 'payoutFix',
    label: 'Fee for payout fixed',
  },
  {
    name: 'ad-percent',
    field: 'adPercent',
    label: 'Commission for advertising payments (%)',
  },
  {
    name: 'prepayment-percent',
    field: 'prepaymentPercent',
    label: 'Prepayment (%)',
  },
];
