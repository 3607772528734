<template>
  <q-dialog ref="dialog" @hide="onDialogHide">
    <q-card class="q-dialog-plugin">
      <q-form @submit="onOKClick">
        <q-card-section class="text-h6"> Change value </q-card-section>
        <q-card-section>
          <q-select
            dense
            v-model="selectedField"
            outlined
            :options="fieldsOptions"
            class="q-mb-sm"
            :rules="[testNotEmpty]"
            hint="Required field*"
          />
          <q-input
            v-if="isValueControlInput"
            dense
            v-model="localValue"
            outlined
            hint="Required field*"
            :rules="[testNotEmpty]"
          />
          <d-user-select
            v-else-if="isValueControlUserSelect"
            v-model="localValue"
            label="Agent"
            :multiple="false"
            use-input
            emit-value
            map-options
            outlined
            hint="Required field*"
            dense
            :options="agentsOptions"
            :rules="[testNotEmpty]"
          />
          <q-select
            v-else-if="isValueControlSelect"
            v-model="localValue"
            label="Currency"
            emit-value
            map-options
            outlined
            dense
            hint="Required field*"
            :options="currencyOptions"
            option-value="id"
            option-label="name"
          />
        </q-card-section>
        <q-card-actions align="right">
          <q-btn color="primary" label="OK" type="submit" />
          <q-btn color="primary" label="Cancel" @click="onCancelClick" />
        </q-card-actions>
      </q-form>
    </q-card>
  </q-dialog>
</template>

<script>
import { columns } from '@/features/balance-limits-table/config';
import DUserSelect from '@/features/user-select';
import { currencyController, usersController } from '@/shared/api';
import { authUtils, notify } from '@/shared/utils';

export default {
  components: {
    DUserSelect,
  },
  props: {
    target: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    currencyOptions: [],
    agentsOptions: [],
    selectedField: null,
    localValue: null,
    isValueControlInput: true,
    isValueControlUserSelect: false,
    isValueControlSelect: false,
  }),
  watch: {
    async selectedField(val) {
      this.isValueControlUserSelect = false;
      this.isValueControlInput = false;
      this.isValueControlSelect = false;

      if (val.label === 'Agent name') {
        await this.loadAgents();
        this.isValueControlUserSelect = true;
      } else if (val.label === 'Currency') {
        await this.loadCurrencies();
        this.isValueControlSelect = true;
      } else {
        this.isValueControlInput = true;
      }

      this.setLocalValueByField(val.value);
    },
  },
  computed: {
    fieldsOptions: () =>
      columns
        .filter((el) => el.name !== 'agent-name' && el.name !== 'currency')
        .map((el) => ({ value: el.field, label: el.label })),
  },
  methods: {
    async loadAgents() {
      try {
        const { data } = await usersController(
          authUtils.getRoleByHierarchy()
        ).agentsForBalance();
        this.agentsOptions = data;
      } catch (e) {
        notify.error();
      }
    },
    async loadCurrencies() {
      try {
        const { data } = await currencyController(
          authUtils.getRoleByHierarchy()
        ).getCurrency();
        this.currencyOptions = data;
      } catch (e) {
        notify.error();
      }
    },
    setLocalValueByField(field) {
      if (typeof field === 'function') {
        this.localValue = field(this.target);
      } else {
        this.localValue = this.target[field];
      }
    },
    testNotEmpty: (str) => !!str || 'Value is empty!',
    show() {
      this.$refs.dialog.show();
    },

    hide() {
      this.$refs.dialog.hide();
    },

    onDialogHide() {
      this.$emit('hide');
    },

    onOKClick() {
      this.$emit('ok', {
        field: this.selectedField,
        value: this.localValue,
      });
      this.hide();
    },

    onCancelClick() {
      this.hide();
    },
  },
};
</script>
