<template>
  <d-grid-card-wrapper>
    <q-card-section>
      <template v-for="prop in simpleProps">
        <div :key="prop.name" class="grid-item__row">
          <div class="grid-item__title">
            {{ prop.label }}
          </div>
          <div class="grid-item__value">
            {{ prop.value }}
          </div>
        </div>
      </template>
    </q-card-section>
    <q-card-actions>
      <q-btn
        dense
        class="full-width"
        icon="mdi-pencil"
        label="Edit"
        color="primary"
      />
    </q-card-actions>
  </d-grid-card-wrapper>
</template>

<script>
import { DGridCardWrapper } from '@/features/grid-card-wrapper';

export default {
  components: { DGridCardWrapper },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    simpleProps: ({ item }) =>
      item.cols.filter((el) => el.name !== 'action-btns'),
  },
};
</script>
