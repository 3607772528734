<template>
  <q-input
    clearable
    :label="label"
    filled
    :value="formatRange(value)"
    dense
    hint="Enter two values separated by ' - ' "
    :rules="[testIsPresentTo, testFromLessTo]"
    @input="setRange($event)"
  />
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      required: true,
    },
    label: {
      type: String,
      default: () => '',
    },
  },
  methods: {
    setRange(str) {
      const [from, to] = (str || '').split('-').map((el) => {
        const tmp = el?.trim() || null;
        return tmp === null ? tmp : +tmp;
      });
      this.$emit('input', { from, to });
    },
    formatRange(value) {
      const { from, to } = value;
      if (!to) {
        return from;
      }
      return `${from} - ${to}`;
    },
    testFromLessTo(str) {
      const [from, to] = str.split('-');
      if (!from && !to) return true;
      return +from <= +to || "'from' not less than 'to'";
    },
    testIsPresentTo(value) {
      const [from, to] = `${value}`.split('-');
      if (from) {
        return !!to || "'to' is not present";
      } else return true;
    },
  },
};
</script>
