<template>
  <d-view-card
    ref="view"
    :fetch-function="feeController.getFees"
    :pagination="pagination"
    :filters="filters"
    :filter-transformer="clearFilters"
  >
    <template #default="{ loading, find, filtersEntity, data }">
      <div class="col-shrink row q-px-md q-pt-md">
        <q-card class="full-width">
          <q-card-section class="row col q-col-gutter-sm">
            <d-range-input
              class="col-md-2 col-6"
              label="Balance Limit from-to"
              v-model="filtersEntity.balanceLimit"
            />
            <d-range-input
              class="col-md-2 col-6"
              label="Fee for deposit % from-to"
              v-model="filtersEntity.refillPercent"
            />
            <d-range-input
              class="col-md-2 col-6"
              label="Fee for deposit fixed from-to"
              v-model="filtersEntity.refillFix"
            />
            <d-range-input
              class="col-md-2 col-6"
              label="Fee for payout % from-to"
              v-model="filtersEntity.payoutPercent"
            />
            <d-range-input
              class="col-md-2 col-6"
              label="Fee for payout fixed from-to"
              v-model="filtersEntity.payoutFix"
            />
            <d-range-input
              class="col-md-2 col-6"
              label="Commission for advertising payments % from-to"
              v-model="filtersEntity.adPercent"
            />
            <d-range-input
              class="col-md-2 col-6"
              label="Prepayment % from-to"
              v-model="filtersEntity.prepaymentPercent"
            />
            <d-user-select
              v-model="filtersEntity.agentId"
              label="Agent"
              class="col-md-4 col-12"
              :multiple="false"
              use-input
              emit-value
              map-options
              filled
              hide-hint
              hide-bottom-space
              dense
              :options="agents"
            />
          </q-card-section>
          <q-card-actions align="right">
            <q-btn
              :disable="loading"
              color="primary"
              label="Apply"
              @click="find"
            />
          </q-card-actions>
        </q-card>
      </div>
      <div class="col-grow">
        <div class="q-pa-md">
          <d-balance-limits-table
            :grid="$q.screen.lt.md"
            :items="data"
            @on-pagination="onPaginationHandler"
            @edit-row="onEditRowHandler"
          />
        </div>
      </div>
    </template>
  </d-view-card>
</template>

<script>
import DEditRowDialog from './features/edit-row-dialog.vue';
import DRangeInput from './features/range-input.vue';
import { DBalanceLimitsTable } from '@/features/balance-limits-table';
import DUserSelect from '@/features/user-select';
import DViewCard from '@/layouts/view-card';
import { INITIAL_FILTERS } from '@/pages/balance/limits-list/config';
import { feeController, usersController } from '@/shared/api';
import { authUtils, notify, objectUtils } from '@/shared/utils';

export default {
  components: {
    DViewCard,
    DUserSelect,
    DRangeInput,
    DBalanceLimitsTable,
  },
  data: () => ({
    agents: [],
    feeController: feeController(authUtils.getRoleByHierarchy()),
    filters: { ...INITIAL_FILTERS },
    pagination: {
      lastId: 0,
      maxResults: 512,
      descending: true,
    },
  }),
  async mounted() {
    await this.loadAgents();
  },
  methods: {
    clearFilters: objectUtils.removeEmptyValuesRecursively,
    async loadAgents() {
      try {
        const { data } = await usersController(
          authUtils.getRoleByHierarchy()
        ).agentsForBalance();
        this.agents = data;
      } catch (e) {
        notify.error();
      }
    },
    async onPaginationHandler({ rowsPerPage }) {
      this.pagination.maxResults = rowsPerPage;
      await this.$refs.view.find();
    },
    onEditRowHandler(row) {
      this.$q
        .dialog({
          component: DEditRowDialog,
          parent: this,
          target: { ...row },
        })
        .onOk(async ({ field, value }) => {
          const payload = { ...row };
          payload[field.value] = value;

          try {
            await this.feeController.updateFee(payload);
            await this.$refs.view.find();
          } catch (e) {
            notify.error(e);
          }
        });
    },
  },
};
</script>
