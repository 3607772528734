<template>
  <d-virtual-table
    :grid="grid"
    :items="items"
    :columns="columns"
    with-pagination
    with-action-btns
    @on-pagination="$emit('on-pagination', $event)"
  >
    <template v-slot:top>
      <q-space />
      <q-btn
        :class="btnClass(true)"
        icon="mdi-wallet-plus"
        color="primary"
        label="Add"
        :to="{ name: 'LimitsEdit' }"
      />
    </template>
    <template v-slot:body-cell-action-btns="{ props }">
      <q-td :props="props">
        <q-btn
          flat
          fab-mini
          icon="mdi-pencil"
          @click="$emit('edit-row', props.row)"
        />
      </q-td>
    </template>
    <template v-slot:grid-item="{ props }">
      <d-grid-item :item="props" />
    </template>
  </d-virtual-table>
</template>

<script>
import { columns } from './config';
import { DGridItem } from './features';
import withActionTableClasses from '@/shared/mixins/withActionTableClasses';
import DVirtualTable from '@/shared/ui/virtual-table';

export default {
  components: {
    DVirtualTable,
    DGridItem
  },
  mixins: [withActionTableClasses],
  props: {
    grid: {
      type: Boolean,
      default: () => false,
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    columns,
  }),
};
</script>

<style scoped lang="scss">
.virtual-scroll-table {
  height: 51em;
}
</style>
